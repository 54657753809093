import gql from 'graphql-tag';

export const GET_USERS = gql`
query userList {
    users {
        totalCount
        edges {
            node {
                id
                firstName
                lastName
                email
                admin
                department
            }
        }
    }
}
`;

export const GET_ITEM = gql`
query item($id: ID) {
    item(id: $id)
}
`;

export const GET_CAMPAIGN_CATEGORIES = gql`
query campaignCategoryList {
    campaignCategories {
        totalCount
        edges {
            node {
                id
                campaignCategoryName
            }
        }
    }
}
`;

export const GET_PRODUCT_CATEGORIES = gql`
query productCategoryList {
    productCategories {
        id
        productCategoryName
    }
}
`;

export const GET_CAMPAIGNS = gql`
query campaignList {
    campaigns {
            id
            campaignName
            campaignCategory {
                id
                campaignCategoryName
            }
            campaignPlans {
                id
                startDt
                endDate
                items {
                    id
                    itemName
                    itemSize
                }
            }
            presentName
            presentMinPrice
            couponMinPrice
            campaignDiscountPrice
            campaignDiscountRate
            updatedAt
            updatedBy
        }
}
`;

export const GET_CAMPAIGN_PLANS = gql`
query allCampaignPlans { 
    allCampaignPlans
}
`;

export const GET_FORWARDING_OFF_PLANS = gql`
query forwardingOffPlans {
    forwardingOffPlans
}
`;

export const GET_CALENDAR_EVENT = gql`
query calendarEvent {
    allCalendarEvent
}
`;

export const GET_SELLING_ITEM_PLANS = gql`
query monthlySellingItems($month: String!) {
    monthlySellingItems(month: $month) 
}
`;

// specifically for Estimations.js
export const GET_ITEM_NAMES_REDUCED = gql`
query itemNames($search: String) {
    itemNames(search: $search) {
        totalCount
        edges{
            node {
                id
                itemName
                itemSize
                hidden
                status
                userDefinedStatus
                itemSellingCycles {
                    id
                    sellingStartDt
                    sellingEndDt
                    regularPrice
                }
            }
        }
    }
}
`;

export const GET_ALL_ITEMS = gql`
query allItems {
    allItems
}
`

export const GET_ITEM_NAMES = gql`
query itemNames {
    itemNames {
        edges {
            node {
                id
                itemName
                itemSize
                updatedAt
                createdAt
                hidden
                status
                userDefinedStatus
                downloadUrl
                updatedBy
                itemSellingCycles {
                    id
                    sellingStartDt
                    sellingEndDt
                    regularPrice
                    updatedAt
                    updatedBy
                }
                itemMappings {
                    id
                    prodId
                    prodName
                    updatedAt
                    updatedBy
                }
            }
        }
        
    }
}
`;

export const GET_ITEM_NAME = gql`
query itemName($itemNameId: Int!) {
    itemName(id: $itemNameId) {
        id
        itemName
        userDefinedStatus
        itemSize
        department
        itemSimilarity {
            id
            mostSimilarItemName {
                itemName
                itemSize
                itemProfile {
                    id
                    itemImgFile
                    itemDetail
                    presignedDownloadUrl
                }
                itemSellingCycles {
                    id
                    regularPrice
                }
            }
            mostSimilarScore
        }
        itemProfile {
            id
            itemImgFile
            itemDetail
            presignedDownloadUrl
        }
        itemSellingCycles {
            id
            regularPrice
            sellingStartDt
            sellingEndDt
        }
    }
}
`;

export const GET_NOUHIN_BY_DATE_RANGE = gql`
query nouhinByDateRange($factoryName: [String!]!, $dateFrom: String!, $dateTo: String!) {
    nouhinByDateRange(factoryName: $factoryName, dateFrom: $dateFrom, dateTo: $dateTo) 
}
`;

export const GET_PRED_BY_DATE_RANGE = gql`
query predByDateRange($factoryName: [String!]!, $dateFrom: String!, $dateTo: String!) {
    predByDateRange(factory: $factoryName, dateFrom: $dateFrom, dateTo: $dateTo) 
}
`;

export const GET_FACTORY_NAMES = gql`
query factoryNames {
    factoryNames
}
`;

export const GET_FACTORY_NOUHIN_COUNT = gql`
query factoryNouhinCount($factoryNames: [String!]!, $itemIds: [ID!]) {
    factoryNouhinCount(factoryNames: $factoryNames, itemIds: $itemIds)
}
`;

export const GET_FACTORY_PREDS_COUNT = gql`
query factoryPredsCount($factoryNames: [String!]!, $itemIds: [ID!]) {
    factoryPredsCount(factoryNames: $factoryNames, itemIds: $itemIds)
}
`;

export const AUTH_CHECK = gql`
query authCheck {
    users {
        totalCount
    }
}`;

export const GET_PRESIGNED_UPLOAD_URL = gql`
query presignedUploadUrl($objectKey: String!) {
    presignedUploadUrl(objectKey: $objectKey) {
        objectKey
        expiresAt
        uploadUrl
    }
}`;

export const GET_INDIVIDUAL_PREDS_COUNTS = gql`
query individualPredsCounts($factoryNames: [String!]!, $itemIds: [ID!]!, $dateFrom: String!, $dateTo: String!) {
    individualNouhinPredCounts(factoryNames: $factoryNames, itemIds: $itemIds, dateFrom: $dateFrom, dateTo: $dateTo)
}`;

export const GET_FAVORITES = gql`
query favorites($userId: ID!) {
    favorites(userId: $userId) {
       id
       itemNameId
    }
}
`;

export const GET_ITEM_CAMPAIGN_PLANS = gql`
query itemCampaignPlans($itemNameId: ID!) {
    itemCampaignPlans(itemNameId: $itemNameId) {
        id
        startDt
        endDate
        campaign {
            id
            campaignName
            campaignCategory {
                id
                campaignCategoryName
            }
            presentName
            presentMinPrice
            couponMinPrice
            campaignDiscountPrice
            campaignDiscountRate
        }
    }
}`;

export const GET_USER_TAGS = gql`
query tags($userId: ID!) {
    tags(userId: $userId) {
        id
        name
        color
    }
}`;

export const GET_ITEM_TAGS = gql`
query itemTags($userId: ID!) {
    itemTags(userId: $userId) {
        id
        tag {
            id
            name
            color
        }
        itemName {
            id
        }
    }
}`;

export const GET_SNACK_SHIPMENTS = gql`
query snackShipmentsGrouped($itemNameIds: [ID!]!) {
    snackShipmentsGrouped(itemNameIds: $itemNameIds)
}`;

export const GET_UNGROUPED_SNACK_SHIPMENTS = gql`
query snackShipments($itemNameIds: [ID!]!, $dateRange: [String!]) {
    snackShipments(itemNameIds: $itemNameIds, dateRange: $dateRange) {
        salesDepartmentAbbreviation
        branchName
        primaryAccountingDestinationName
        numberOfCases
        productId
        shippingDate
        shippingYearMonth
        shippingDestinationNameKana
    }
}`;

export const GET_UNGROUPED_PREDS = gql`
query ungroupedPreds($itemNameIds: [ID!]!, $dateRange: [String!]) {
    ungroupedPreds(itemNameIds: $itemNameIds, dateRange: $dateRange)
}`;

export const GET_WEEK_MONTH_GROUPED_SNACK_SHIPMENTS = gql`
query weekMonthGroupedSnackShipments($itemNameIds: [ID!]!, $dateRange: [String!], $groupBy: String!, $division: String, $selectedDivisions: [[String!]!]) {
    weekMonthGroupedSnackShipments(itemNameIds: $itemNameIds, dateRange: $dateRange, groupBy: $groupBy, division: $division, selectedDivisions: $selectedDivisions)
}`;

export const GET_DIVISIONS = gql`
query divisions($itemNameIds: [ID!]!, $dateRange: [String!]) { 
    divisions(itemNameIds: $itemNameIds, dateRange: $dateRange)
}`;

export const GET_WEEK_MONTH_GROUPED_SNACK_PREDS = gql`
query weekMonthGroupedSnackPreds($itemNameIds: [ID!]!, $dateRange: [String!], $groupBy: String!, $division: String, $selectedDivisions: [[String!]!]) {
    weekMonthGroupedSnackPreds(itemNameIds: $itemNameIds, dateRange: $dateRange, groupBy: $groupBy, division: $division, selectedDivisions: $selectedDivisions)
}`;
